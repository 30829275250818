import React from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import BlogsPosts from "../../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../../component/Blog/ShareLinks";
import AuthorAvatar from "../../component/Blog/AuthorAvatar";
import CommonParagraph from "../../component/Blog/CommonParagraph";
import Heading from "../../component/Blog/Heading";
import BlogLink from "../../component/Blog/BlogLink";
import { blogList } from "../../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter(
    (blog) => blog.url == "/social-media-tips-for-san-diego-home-sellers"
  );

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <a
                  href={blogData[0].authorUrl && blogData[0].authorUrl}
                  className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]"
                >
                  By {blogData[0].author}
                </a>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../../images/The Selby Team_How to Use Social Media to Attract Buyers in San Diego.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                Today’s real estate market is fierce, both for buyers and sellers. If you’re
                thinking of selling your San Diego home so you can start the next chapter of your
                life, you’re probably interested in how to get top dollar for your property. The
                best way to do just that? Create a bidding war between buyers, driving offers up.
              </CommonParagraph>
              <CommonParagraph>
                Social media can be a powerful way to generate more interest and, ultimately, more
                competitive offers. With its vast reach and powerful targeting tools, certain
                platforms can help you showcase your property and connect with buyers who may not be
                in the immediate vicinity.
              </CommonParagraph>
              <CommonParagraph>
                Here’s a comprehensive guide on how to sell your home using social media…and a
                little help from our expert realtors, of course!
              </CommonParagraph>
              <Heading type="h2">Choose the Right Platform</Heading>
              <CommonParagraph>
                If you’ve ever scrolled through different platforms, you know they’re not all
                created equal; that’s even more true for real estate marketing. When it comes to the
                best social media for selling homes, consider the following:
              </CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px]">
                <li>
                  <strong>Facebook:</strong> Thanks to its extensive reach and advanced targeting
                  features, Facebook is ideal for reaching potential buyers in San Diego and
                  surrounding areas. Share quality photos and updates to get more eyes on your
                  property. Facebook also has great features that can help you produce some of the
                  best home-selling ads if you ever need to take that route.
                </li>
                <li>
                  <strong>Instagram:</strong> Is your home a breathtaking beauty? Instagram is
                  perfect for showcasing visually stunning listings. With help from photos and short
                  videos, it’s easy to highlight the best features of your property. Additionally,
                  you can leverage Stories and Reels to show the overall appeal of living in San
                  Diego, generating more interest in your home’s location.
                </li>
                <li>
                  <strong>LinkedIn:</strong> In a growing business hub like San Diego, LinkedIn can
                  be an excellent platform for connecting with those looking for investment
                  properties. When utilizing LinkedIn to help{" "}
                  <BlogLink
                    url="https://selbysellssd.com/sell-your-san-diego-home/"
                    text="sell your home"
                  />
                  , consider sharing market insights, property data, and local updates that will
                  help position you as a knowledgeable resource.
                </li>
              </ul>
              <Heading type="h2">Create Compelling Content</Heading>
              <CommonParagraph>
                No matter the social media platform you choose, posting compelling content is key.
                Engaging posts help capture buyer attention, drawing them in, so be sure to invest
                time and resources when creating the following:
              </CommonParagraph>
              <Heading type="h3">Photos and Videos</Heading>
              <CommonParagraph>
                Visuals can make or break a listing. If you can’t have professional photos taken, be
                sure to pay close attention to lighting,{" "}
                <BlogLink
                  url="https://selbysellssd.com/real-estate-marketing-services"
                  text="staging your home"
                />
                , and architectural details when capturing images. When filming videos, focus on
                providing a sense of flow and space.
              </CommonParagraph>
              <Heading type="h3">Engaging Descriptions</Heading>
              <CommonParagraph>
                An effective description can give your listing a real boost, intriguing buyers and
                encouraging them to come to an open house. When writing descriptions on social
                media, don’t forget to highlight lifestyle benefits as well, such as nearby beaches
                or entertainment options. It’s also important to tailor each description to both the
                audience and the platform you’re using.
              </CommonParagraph>
              <Heading type="h3">Virtual Tours</Heading>
              <CommonParagraph>
                A virtual tour is a great way to allow potential buyers to fully immerse themselves
                in your property. They’re especially useful for out-of-town buyers looking to
                relocate to San Diego, helping them feel more confident in your listing.
              </CommonParagraph>
              <Heading type="h2">Leverage Paid Advertising</Heading>
              <CommonParagraph>
                Targeted ads, boosted posts, and retargeting can give your house listing a leg up on
                the competition. That’s because paid advertising allows you to enhance visibility by
                reaching a specific buyer demographic, and even retarget interested viewers or those
                who have looked at similar properties.
              </CommonParagraph>
              <CommonParagraph>
                Think about your property, the type of family best suited to buy it, and even the
                lifestyle it offers. You can use those insights to speak directly to your ideal
                buyer, helping you more effectively capture interest.
              </CommonParagraph>
              <Heading type="h2">Engage With Your Audience</Heading>
              <CommonParagraph>
                When it comes to building trust and relationships with potential buyers, audience
                engagement is crucial. Here are two quick tips to help you connect with potential
                buyers:
              </CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px]">
                <li>
                  Respond to inquiries: Thorough, prompt responses show you’re attentive and eager
                  to help. Answering questions about your property and neighborhood can help
                  maintain interest and encourage further inquiries.
                </li>
                <li>
                  Participate in relevant discussions: Joining conversations related to San Diego
                  real estate can help showcase your expertise in the local market.
                </li>
              </ul>
              <Heading type="h2">Analyze and Adjust Your Strategy</Heading>
              <CommonParagraph>
                When it comes to social media, you never want to adopt a “set it and forget it”
                mentality, especially when it comes to selling your home. Use performance analytics
                to assess what types of posts generate the most engagement and interest in your
                property, and update your strategy to include more of what’s resonating.
              </CommonParagraph>
              <CommonParagraph>
                If a certain platform isn’t delivering, experiment with different formats and
                posting times to see if you can achieve better results. If you’ve adjusted your
                strategy and tactics but are still not seeing good results, it may be time to focus
                on other platforms.
              </CommonParagraph>
              <Heading type="h2">Lean On The Selby Team’s Social Circle</Heading>
              <CommonParagraph>
                As local San Diego realtors, we have a wide social circle that includes potential
                buyers for your property. When you’re ready to sell your San Diego home, we’ll
                leverage every resource available to us, including our vast on- and offline network,
                to ensure you get top dollar for your property. Learn more about{" "}
                <ContactSlideoutLink text="selling your home" /> with help from The Selby Team
                today!
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
