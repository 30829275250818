import React from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import BlogsPosts from "../../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../../component/Blog/ShareLinks";
import AuthorAvatar from "../../component/Blog/AuthorAvatar";
import CommonParagraph from "../../component/Blog/CommonParagraph";
import Heading from "../../component/Blog/Heading";
import BlogLink from "../../component/Blog/BlogLink";
import { blogList } from "../../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter((blog) => blog.url == "/ocean-view-home-prices-sd");

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <a
                  href={blogData[0].authorUrl && blogData[0].authorUrl}
                  className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]"
                >
                  By {blogData[0].author}
                </a>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../../images/The Selby Team_How Home Prices Vary for Properties with Ocean Views in San Diego.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                It’s no surprise that ocean-view properties command premium prices. But in San
                Diego, those prices can vary greatly from one location to the next.
              </CommonParagraph>
              <CommonParagraph>
                While “ocean view” probably makes you think of Pacific vistas and wide-open blue
                skies, the reality is a bit more complex. Price tags vary greatly depending on the
                quality of the view, the neighborhood, and the property’s actual distance from the
                coast.
              </CommonParagraph>
              <Heading type="h2">What Makes Ocean-View Properties Valuable?</Heading>
              <CommonParagraph>
                Buyers are attracted to ocean-view properties for obvious reasons—calming views,
                beach access, fresh sea breezes, and the prestige associated with owning prime real
                estate.
              </CommonParagraph>
              <CommonParagraph>
                Beyond that, buyers are motivated by strong appreciation and resale value.
              </CommonParagraph>
              <CommonParagraph>
                Historically, luxury ocean beach homes in San Diego have proven to be stable,
                recession-resistant investments. While demand cooled in the post-pandemic era, we've
                seen a steady recovery since 2023, driven by limited inventory and growing demand.
              </CommonParagraph>
              <Heading type="h2">What Drives Ocean Beach Home Values in San Diego?</Heading>
              <CommonParagraph>
                If you've spent any time browsing{" "}
                <BlogLink
                  url="https://homes.selbysellssd.com/idx/map/mapsearch"
                  text="San Diego properties"
                />
                , you know definitions of “ocean view” vary greatly.
              </CommonParagraph>
              <CommonParagraph>
                To some, it means a direct beachfront location with unobstructed views; to others,
                it means looking through power lines to catch a distant glimpse of the Pacific. The
                quality of the view is only one factor that influences price-setting strategies.
                Here are others:
              </CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px] flex flex-col gap-y-[10px]">
                <li>
                  <strong>Proximity to water:</strong> As a general rule, the closer a home is to
                  the Pacific, the heftier the price tag. For example, a house directly on the beach
                  in{" "}
                  <BlogLink
                    url="https://selbysellssd.com/san-diego-communities/coronado-homes-for-sale"
                    text="Coronado"
                  />{" "}
                  can cost two to three times more than a similar inland property in{" "}
                  <BlogLink
                    url="https://selbysellssd.com/san-diego-communities/north-park-homes-for-sale"
                    text="North Park"
                  />
                  .
                </li>
                <li>
                  <strong>Quality of the view:</strong> We touched on this above, but let’s explore
                  view quality in more detail. Unobstructed views from multiple angles on the
                  property would significantly boost property value compared to partial or distant
                  views. And if you can spot regional landmarks like Crystal Pier or the La Jolla
                  Cliffs, expect to pay even more.
                </li>
                <li>
                  <strong>Property features:</strong> Square footage certainly influences price, but
                  so do lot size and the condition of the home. A 1,500-foot property with modern
                  features, ample outdoor space, and unobstructed ocean views will likely command a
                  higher price than an inland property with similar features and twice the square
                  footage.
                </li>
                <li>
                  <strong>Neighborhood:</strong> Location affects views as much as it does price. La
                  Jolla, for example, is known for its luxury housing options, and the prices
                  reflect it. On the other hand,{" "}
                  <BlogLink
                    url="https://selbysellssd.com/san-diego-communities/pacific-beach-homes-for-sale"
                    text="Pacific Beach"
                  />{" "}
                  offers more affordable options.
                </li>
                <li>
                  <strong>Market trends:</strong> Like all real estate, ocean-view properties are
                  not impervious to the whims of the market. While the demand for beach homes for
                  sale in San Diego dipped post-COVID, we’ve seen steady growth since 2023.
                </li>
              </ul>
              <Heading type="h2">
                Considerations When Shopping For Ocean View Homes in San Diego
              </Heading>
              <CommonParagraph>
                Beyond the initial sale price, there are other factors to consider before purchasing
                ocean beach homes in San Diego:
              </CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px] flex flex-col gap-y-[10px]">
                <li>
                  <strong>Flood zones:</strong> Because ocean-view properties are more susceptible
                  to flooding, you may want to look for properties situated at higher elevations.
                </li>
                <li>
                  <strong>Insurance:</strong> Properties in flood zones typically carry higher
                  insurance premiums. Some insurers may limit coverage or require separate flood
                  insurance policies.
                </li>
                <li>
                  <strong>Erosion:</strong> Another factor to consider is whether the property is
                  vulnerable to erosion—particularly if it is located on a bluff or sandy
                  foundation.
                </li>
                <li>
                  <strong>Parking:</strong> Many coastal areas, especially popular tourist
                  destinations, have limited parking. Consider whether the property includes
                  dedicated parking or if street parking is readily available.
                </li>
              </ul>
              <Heading type="h2">Ride the Wave to Your San Diego Dream Home</Heading>
              <CommonParagraph>
                Looking for ocean beach homes for sale in San Diego? Let the Selby Team be your
                guide. Whether you’re looking for unobstructed views or simply wish to be a quick
                jaunt to the nearest beach, our team will help you find your next dream property.
                <ContactSlideoutLink text="Contact us" /> today to begin your search!
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
